/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import { Checkbox, PrimaryButton, Spinner, Stack, Text, mergeStyles, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import TickIcon from '../../assets/validate-tick.png';
import ErrorIcon from '../../assets/validate-error.png';
import { searchFormStyles } from './SearchFormStyles';
import axios from 'axios';
import { API } from '../../config';
import Arrow from '../../assets/caret_down.svg';

const noValidateIcon = 'none';
const validateTickIcon = `url(${TickIcon})`;
const validateErrorIcon = `url(${ErrorIcon})`;
const arrowIcon = `url(${Arrow})`;

type SearchForm = {
  name: string;
  value: string;
  key: string;
};

interface SearchFormFields {
  FIRST_NAME: SearchForm;
  LAST_NAME: SearchForm;
  STATE: SearchForm;
  NPI_ID: SearchForm;
}

interface Physician {
  name: string;
  address: string;
}

const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AA', label: 'Armed Forces America' },
  { value: 'AE', label: 'Armed Forces Europe/CANADA/Middle East/Africa' },
  { value: 'AP', label: 'Armed Forces Pacific' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MP', label: 'Mariana Islands, Northern' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

const input: SearchFormFields = {
  FIRST_NAME: {
    name: 'firstName',
    value: '',
    key: 'FIRST_NAME',
  },
  LAST_NAME: {
    name: 'lastName',
    value: '',
    key: 'LAST_NAME',
  },
  STATE: {
    name: 'state',
    value: '',
    key: 'STATE',
  },
  NPI_ID: {
    name: 'npiId',
    value: '',
    key: 'NPI_ID',
  },
};


interface NpiData {
  number: string;
  addresses: {
    address_1: string;
    address_2?: string;
    city: string;
    state: string;
    postal_code: string;
  }[];
  basic: {
    first_name: string;
    last_name: string;
    middle_name: string;
  };
}
export const SearchForm = (prop: any): JSX.Element => {
  const { inputs, onSubmit, isSubmitted, isMobile } = prop;
  const [inputValues, setInputValues] = useState<SearchFormFields>(input);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [finalSubmitDisabled, setFinalSubmitDisabled] = useState(true);
  const [npiSubmitDisabled, setNpiSubmitDisabled] = useState(true);
  const [firstNameValidatorIcon, setFirstNameValidatorIcon] = useState(noValidateIcon);
  const [lastNameValidatorIcon, setLastNameValidatorIcon] = useState(noValidateIcon);
  const [stateValidatorIcon, setStateValidatorIcon] = useState(arrowIcon);
  const [npiIdValidatorIcon, setNpiIdValidatorIcon] = useState(noValidateIcon);
  const [physiciansList, setPhysiciansList] = useState<Physician[]>([]);
  const [isDetailsForm, setDetailsForm] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [searchBy, setSearchBy] = useState('NPI');
  const [noPhysiciansFound, setNoPhysiciansFound] = useState(false);
  const theme = useTheme();
  const styles = searchFormStyles(theme);
  const [selectedPhysician, setSelectedPhysician] = useState({});
  const [selectedPhysicianName, setSelectedPhysicianName] = useState('');
  const [rawNpiData, setRawNpiData] = useState<NpiData[]>([]);
  const defaultOnChange = (key: string, value: string) => {
    inputValues[key as keyof SearchFormFields].value = value;

    // Validator Icons
    switch (key) {
    case 'FIRST_NAME':
      if (!value || value === '') setFirstNameValidatorIcon(noValidateIcon);
      else if (value.length >= 0) setFirstNameValidatorIcon(validateTickIcon);
      else setFirstNameValidatorIcon(validateErrorIcon);
      break;
    case 'LAST_NAME':
      if (!value || value === '') setLastNameValidatorIcon(noValidateIcon);
      else if (value.length >= 1) setLastNameValidatorIcon(validateTickIcon);
      else setLastNameValidatorIcon(validateErrorIcon);
      break;
    case 'STATE':
      //will add state validation here
      if (!value || value === '') setStateValidatorIcon(arrowIcon);
      else setStateValidatorIcon(validateTickIcon);
      break;
    case 'NPI_ID':
      //will add state validation here
      if (!value || value === '') setNpiIdValidatorIcon(noValidateIcon);
      else if (value.length == 10) setNpiIdValidatorIcon(validateTickIcon);
      else setNpiIdValidatorIcon(validateErrorIcon);
      break;
    default:
      break;
    }

    // Validate Input values
    let validInputs = true;
    let validNpiId = true;
    if (inputValues?.LAST_NAME.value === '' || inputValues?.STATE.value === '') {
      validInputs = false;
    }
    console.log('inputValues?.NPI_ID.value', inputValues?.NPI_ID.value.length);
    if (inputValues?.NPI_ID.value === '' || inputValues?.NPI_ID.value.length !== 10) {
      validNpiId = false;
    }
    console.log('inputValues', inputValues);
    setSubmitDisabled(!validInputs);
    setNpiSubmitDisabled(!validNpiId);
    setInputValues(inputValues);
  };


  const finallySubmit = () => {
    onSubmit && onSubmit(inputs);
  };

  const handleSubmit = (value: string) => {
    console.log(inputValues);
    //set value to inputs value where key is physician

    const transformedData = rawNpiData
      .filter((physician: any) => {
        const fullName = `${physician.basic.first_name} ${physician.basic.last_name}`;
        if(isDetailsForm){
          return fullName === value && physician.addresses.some((address: any) => address.state === inputValues?.STATE.value);
        } else {
          return fullName === value;
        }
      })
      .map((physician: any) => {
        return physician;
      });
    

    inputs.forEach((input: any) => {
      if (input.key === 'PHYSICIAN') {
        setSelectedPhysicianName(value);
        input.value = JSON.stringify(transformedData[0]);
        setSelectedPhysician(JSON.stringify(transformedData[0])); 
        setFinalSubmitDisabled(false);
      } else {
        setSelectedPhysicianName('');
        setSelectedPhysician(''); 
        setFinalSubmitDisabled(true);
      }
    });
    console.log('selectedPhysician', selectedPhysician);
  };

  const handleSearch = async (isNpi = false) => {
    setIsLoading(true);
    setNoPhysiciansFound(false);
    setPhysiciansList([]);
    setRawNpiData([]);
    // const apiUrl = `${API}/npi-search/${SESSION_ID}`;
    const apiUrl = `${API}/npi-search`;
    // const apiUrl = 'https://npiregistry.cms.hhs.gov/api/?version=2.1&limit=5';

    const params = isNpi
      ? { npi: inputValues?.NPI_ID.value }
      : {
        firstName: inputValues?.FIRST_NAME.value,
        lastName: inputValues?.LAST_NAME.value,
        state: inputValues?.STATE.value,
      };
    try {
      const res = await axios.get(`${apiUrl}`, { params });

      if (res.status === 200) {
        const response = res.data.data.results;
        if (response.length === 0) {
          setNoPhysiciansFound(true);
        } else {
          setRawNpiData(response);
          const transformedData = response
            .map((response: any) => {
              console.log('response', response);
              console.log('response.addresses', response.addresses[0]);
              let address = response.addresses[0];
              if(inputValues?.STATE.value){
                console.log('geeting state', inputValues?.STATE.value);
                const filterAddress = response.addresses.find(
                  (address: any) => address.state == inputValues?.STATE.value
                );
                if(filterAddress){
                  address = filterAddress;
                }
              }            

              console.log('address', address, response.addresses);
              return address && {
                name: `${response.basic.first_name} ${response.basic.last_name}`,
                address: `${address.city}, ${address.state} ${address.postal_code}`,
              };
            })
            .filter(Boolean);

          transformedData && setPhysiciansList(transformedData);
        }
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  const handleFormChange = () => {
    //reset form values
    setInputValues(input);    
    defaultOnChange('FIRST_NAME', '');
    defaultOnChange('LAST_NAME', '');
    defaultOnChange('STATE', '');
    defaultOnChange('NPI_ID', '');
    setDetailsForm(!isDetailsForm);
    setSearchBy(!isDetailsForm ? 'NPI' : 'Name & Place');
    setPhysiciansList([]);
    setNoPhysiciansFound(false);
  };

  useEffect(() => {
    console.log('inputValues', inputValues);
  }, [inputValues, handleFormChange]);

  return (
    <Stack horizontalAlign="center" style={{ width: isMobile ? '100%' : '85%', pointerEvents: isSubmitted ? 'none' : 'auto' }}>
      {isDetailsForm && (
        <>
          <Stack horizontalAlign="center" style={{ width: '100%' }}>
            <Text style={{ color: '#545C64', margin: isMobile ? '0.5rem' : '2rem 2rem 0rem 2rem', fontSize: isMobile ? '14px' : '18px' }}>
              Let's confirm your physician! Search using the fields below:
            </Text>
            <Text style={{ color: '#787878', margin: '0.5rem 0.5rem 1rem 0.5rem', fontSize: '14px' , fontStyle: 'italic' }} >
              Healthcare provider details are necessary to obtain full benefits of this program.
            </Text>
            <ul  style={{maxWidth:'400px' }} className={mergeStyles(styles.ul)} >
              <Stack horizontal horizontalAlign="center" wrap styles={{ root: {flexWrap: 'wrap', justifyContent: 'space-between' } }}>
                <li style={{margin: isMobile ? '0.2rem' : '0 1rem 1.2rem 1rem'}}>
                  <Stack style={{ width: '100%', maxWidth: isMobile ? '100px' : '140px', }}>
                    <input
                      className={mergeStyles(styles.inputField)}
                      type="text"
                      name="firstName"
                      defaultValue={inputValues?.FIRST_NAME.value}
                      placeholder="First Name"
                      onChange={(value) => {
                        defaultOnChange('FIRST_NAME', value.target.value);
                      }}
                      style={{
                        backgroundImage: `${firstNameValidatorIcon}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right top',
                        paddingLeft: '0.2rem',
                      }}
                    />
                  </Stack>
                </li>
                <li style={{margin: isMobile ? '0.2rem' : '0 1rem 1.2rem 1rem'}} >
                  <Stack style={{ width: '100%', maxWidth: isMobile ? '100px' : '140px',  }}>
                    <input
                      className={mergeStyles(styles.inputField)}
                      type="text"
                      name="lastName"
                      defaultValue={inputValues?.LAST_NAME.value}
                      placeholder="Last Name*"
                      onChange={(value) => {
                        defaultOnChange('LAST_NAME', value.target.value);
                      }}
                      style={{
                        backgroundImage: `${lastNameValidatorIcon}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right top',
                        paddingLeft: '0.2rem',
                      }}
                    />
                  </Stack>
                </li>
                <li  style={{margin: isMobile ? '0.2rem' : '0 1rem 1.2rem 1rem'}}>
                  <Stack style={{ width: '100%', maxWidth: isMobile ? '100px' : '140px', }}>
                    <select
                      className={mergeStyles(styles.selectField)}
                      name="state"
                      defaultValue={inputValues?.STATE.value}
                      onChange={(value) => {
                        defaultOnChange('STATE', value.target.value);
                      }}
                      style={{
                        backgroundImage: `${stateValidatorIcon}`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'right top',
                        paddingLeft: '0.2rem',
                        backgroundColor: '#fff',
                        color: inputValues?.STATE.value ? theme.palette.black : '#545C6480',
                      }}
                    >
                      <option value="" hidden disabled>
                        State*
                      </option>
                      {states.map((state) => (
                        <option key={state.value} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                      
                    </select>
                  </Stack>
                </li>
                <li style={{
                  border: 'none',
                  borderRadius: 'none',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                  fontSize: isMobile ? '14px': '16px',
                  // alignSelf: 'center',
                  margin: isMobile ? '0.35rem' : '0 1rem 1.2rem 1rem'
                }}>
                  <Stack
                    horizontalAlign="center"
                    style={{ color: '#242482', cursor: 'pointer' }}
                    onClick={handleFormChange}
                  >
                    <u>Or Search by {searchBy}</u>
                  </Stack>
                </li>
              </Stack>
            </ul>
          </Stack>
          <Stack horizontal={!isMobile} horizontalAlign="center" style={{maxWidth: isMobile ? '400px' : '500px'}}>
            {/* <PrimaryButton
              className={mergeStyles({
                marginTop: '1rem',
                border: '1px solid #242482',
                borderRadius: theme.effects.roundedCorner6,
                height: '42px',
                fontSize: '16px',
                fontWeight: '700',
                boxShadow: theme.effects.elevation8,
                color: '#545C64',
                background: '#FFFFFF',
                
                ':disabled': {
                  background: '#D1D1D1',
                  color: '#545C64',
                  pointerEvent: 'none !important',
                },
                ':active': {
                  background: '#FFFFFF',
                  border: '1px solid #242482',
                  color: '#545C64',
                },
                ':hover': {
                  background: '#FFFFFF',
                  border: '1px solid #242482',
                  color: '#545C64',
                },
                maxWidth: '181px',
              })}
              
              onClick={() => handleSubmit('I don\'t know')}
            >
              <Stack style={{ padding: isMobile ? '0' :'5px' }}>
                <Checkbox
                  className="cardcheckbox"
                  styles={{
                    checkbox: {
                      backgroundColor: '#F6F6F6',
                      borderWidth: '1.2px',
                      border: '1px solid #C7C7C7 !important',
                      // borderRadius: '50%',
                      height: '16px',
                      width: '16px',
                      
                    },
                    checkmark: {
                      fontSize: '0px',
                      width: '8px',
                      height: '8px',
                      textAlign: 'center',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      verticalAlign: 'middle',
                      color: '#F8D347 !important',
                      backgroundColor: '#F8D347 !important',
                      zIndex: 1,
                      borderRadius: '2px !important',
                      position: 'relative',
                    },
                  }}
                  checked={selectedPhysicianName === 'I don\'t know'}
                />
              </Stack>
              I don't know
            </PrimaryButton>
            <Stack horizontal verticalAlign='center' horizontalAlign='center' style={{margin: isMobile ? '0.2rem' :'1rem'}}>
              <span >or</span>
            </Stack> */}
            <PrimaryButton
              disabled={submitDisabled}
              className={mergeStyles(styles.button)}
              styles={{
                rootDisabled: {
                  backgroundColor: '#fff',
                  color: '#999',
                  pointerEvents: 'none !important',
                },
              }}
              onClick={() => handleSearch()}
            >
              Search Physician
            </PrimaryButton>
          </Stack>
        </>
      )}
      {!isDetailsForm && (
        <>
          <Stack styles={{ root: styles.containerRoot }}>
            <Text style={{ color: '#545C64', margin: isMobile ? '0.5rem' : '2rem 2rem 0rem 2rem', fontSize: isMobile ? '14px' : '18px' }}>
              Let's confirm your physician! Search using the fields below:
            </Text>
            <Text style={{ color: '#787878', margin: '0.5rem 0.5rem 1rem 0.5rem', fontSize: '14px' , fontStyle: 'italic' }} >
              Healthcare provider details are necessary to obtain full benefits of this program.
            </Text>
            <ul className={mergeStyles(styles.ul)}>
              <Stack horizontal horizontalAlign='center'>
                <Stack style={{ width: '100%', maxWidth: '200px' }}>
                  <li>
                    <Stack style={{ width: '100%', maxWidth: '200px' }}>
                      <input
                        className={mergeStyles(styles.inputField)}
                        type="text"
                        name="npiId"
                        defaultValue={inputValues?.NPI_ID.value}
                        placeholder="NPI*"
                        onChange={(event) => {
                          const value = event.target.value;
                          if (!isNaN(Number(value))) {
                            // If the value is numeric, update the state
                            console.log('value', value);
                            defaultOnChange('NPI_ID', value);
                          }
                        }}
                        pattern="[0-9]*"
                        style={{
                          backgroundImage: `${npiIdValidatorIcon}`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'right top',
                          paddingLeft: '0.2rem',
                        }}
                        maxLength={10}
                        max={10}
                        onKeyDown={(event) => {
                          const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'];
                          
                          if (!/^\d$/.test(event.key) && !allowedKeys.includes(event.key) && !event.ctrlKey && !event.metaKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Stack>
                  </li>
                </Stack>
              </Stack>
            </ul>
          </Stack>
          <Stack
            horizontalAlign="center"
            style={{ color: '#242482', cursor: 'pointer', fontSize: '16px', fontFamily: 'Open Sans' }}
            onClick={handleFormChange}
          >
            <u>Or Search by {searchBy}</u>
          </Stack>
          <Stack horizontal={!isMobile} horizontalAlign="center" style={{maxWidth: isMobile ? '400px' : '500px'}}>
            {/* <PrimaryButton
              className={mergeStyles({
                marginTop: '1rem',
                border: '1px solid #242482',
                borderRadius: theme.effects.roundedCorner6,
                height: '42px',
                fontSize: '16px',
                fontWeight: '700',
                boxShadow: theme.effects.elevation8,
                color: '#545C64',
                background: '#FFFFFF',
                
                ':disabled': {
                  background: '#D1D1D1',
                  color: '#545C64',
                  pointerEvent: 'none !important',
                },
                ':active': {
                  background: '#FFFFFF',
                  border: '1px solid #242482',
                  color: '#545C64',
                },
                ':hover': {
                  background: '#FFFFFF',
                  border: '1px solid #242482',
                  color: '#545C64',
                },
                maxWidth: '181px',
              })}
              
              onClick={() => handleSubmit('I don\'t know')}
            >
              <Stack style={{ padding: isMobile ? '0' :'5px' }}>
                <Checkbox
                  className="cardcheckbox"
                  styles={{
                    checkbox: {
                      backgroundColor: '#F6F6F6',
                      borderWidth: '1.2px',
                      border: '1px solid #C7C7C7 !important',
                      // borderRadius: '50%',
                      height: '16px',
                      width: '16px',
                      
                    },
                    checkmark: {
                      fontSize: '0px',
                      width: '8px',
                      height: '8px',
                      textAlign: 'center',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      verticalAlign: 'middle',
                      color: '#F8D347 !important',
                      backgroundColor: '#F8D347 !important',
                      zIndex: 1,
                      borderRadius: '2px !important',
                      position: 'relative',
                    },
                  }}
                  checked={selectedPhysicianName === 'I don\'t know'}
                />
              </Stack>
              <span
                style={{
                  marginTop: '4px',
                }}
              >
                I don't know
              </span>
            </PrimaryButton>
            <Stack horizontal verticalAlign='center' horizontalAlign='center' style={{margin: isMobile ? '0.2rem' :'1rem'}}>
              <span >or</span>
            </Stack> */}
            <PrimaryButton
              disabled={npiSubmitDisabled}
              className={mergeStyles(styles.button)}
              styles={{
                rootDisabled: {
                  backgroundColor: '#fff',
                  color: '#999',
                  pointerEvents: 'none !important',
                },
              }}
              onClick={() => handleSearch(true)}
            >
              <span
                style={{
                  marginTop: '4px',
                }}
              >
                Search Physician
              </span>
            </PrimaryButton>
          </Stack>
          
        </>
      )}
      

      <Stack  style={{ marginTop: '1rem', border: 'none' }}>
        {isLoading && <Spinner />}
        <Stack horizontalAlign="center" styles={{ root: styles.containerRoot }} style={{ border: 'none' }}>
          {physiciansList && physiciansList.length > 0 && (<Text style={{ color: '#545C64', margin: '2rem',marginBottom: '1rem', fontSize: isMobile ? '14px':'18px',fontFamily: 'Helvetica', }}>
            Physicians based on your search:
          </Text>)}
          <ul className={mergeStyles(styles.ul)} style={{border : 'none !important', maxWidth: isMobile ? 'auto' :'750px', width: isMobile ? '115%' :'auto'}}>
            <Stack horizontalAlign="space-between" horizontal wrap styles={{ root: {flexWrap: 'wrap', justifyContent: 'space-between' } }}>
              {physiciansList.map((physician, index) => {
                return (
                  <li key={index} style={{width: isMobile ? '150px' :'300px', border : 'none', padding: isMobile ? '12px 6px' : '8px 12px', margin: isMobile ? '0 0 1.2rem 0' :'0 1rem 1.2rem 1rem',}}>
                    <Stack key={index} horizontal>
                      <Stack
                        styles={{ root: { cursor: 'pointer', alignContent: 'ce' } }}
                        onClick={() => handleSubmit(physician.name)}
                        onTouchStart={() => handleSubmit(physician.name)}
                        role="button"
                      >
                        <Stack
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            color: '#000000',
                          }}
                        >
                          <Stack style={{ padding: isMobile ? '1px' :'5px' }}>
                            <Checkbox
                              className="cardcheckbox"
                              styles={{
                                checkbox: { ...styles.checkBoxContainer.checkbox , width: '14px', height: '14px' },
                                checkmark: {
                                  ...styles.checkBoxContainer.checkmark,
                                  position: 'relative',
                                },
                              }}
                              checked={selectedPhysicianName === physician.name}
                            />
                          </Stack>
                          <Stack>
                            <Text style={{ color: '#545C64', fontSize: isMobile ? '12px' : 'auto' }}>
                              <b>{physician.name}</b> <br />
                              <span style={{ color: '#545C64', fontSize: isMobile ? '10px' : '15px'}}>
                                {physician.address}
                              </span>
                            </Text>
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  </li>
                );
              })}
            </Stack>
          </ul>
        </Stack>
        {noPhysiciansFound && (
          <>
            <Text 
              style={{
                color: theme.palette.black,
                fontSize: '18px',
                fontFamily: 'Helvetica',
              }}
            >
              Physicians based on your search:
            </Text>
            <Stack
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #DADADA',
                borderRadius: theme.effects.roundedCorner6,
                padding: '0.7rem',
                // marginTop: '1rem',
                textAlign: 'start',
                marginTop: '1rem' 
              }}
            > 
            
          
              <Text style={{ color: '#545C64', marginTop: '0.5rem',  fontSize: '18px', fontFamily: 'Open Sans' }}>
                <span style={{ fontWeight: 600 }}>No Physicians Found</span><br />
                <span style={{ color: '#545C64', fontSize: '16px' }}>
                  No physicians found with the details that are provided. Please try again.
                </span>
              </Text>
            </Stack>
          </>
        )}
        <Stack horizontalAlign='center'>

          {!isSubmitted && (
            <>
              <PrimaryButton
                disabled={finalSubmitDisabled}
                className={mergeStyles(styles.submitButton)}
                styles={{
                  rootDisabled: {
                    backgroundColor: '#fff',
                    color: '#999',
                  },
                }}
                onClick={finallySubmit}
              >
                SUBMIT
              </PrimaryButton>
            </>
          )}
          {/* {(finalSubmitDisabled || !isSubmitted) && (
            <>
              <PrimaryButton
                disabled={isSubmitted}
                className={mergeStyles(styles.button)}
                onClick={finallySubmit}
              >
                <span style={{ marginTop: '4px' }}>I Don't Know</span>
              </PrimaryButton>
            </>
          )} */}
        
        </Stack>
       
      </Stack>
    </Stack>
  );
};
