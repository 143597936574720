import { Checkbox, Link, mergeStyles, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { BaseComponentProps, Input } from '../types';
import { contextualFormStyles } from './ContextualFormStyles';
import Validator from 'validator';
import TickIcon from '../../assets/validate-tick.png';
import ErrorIcon from '../../assets/validate-error.png';
import Arrow from '../../assets/caret_down.svg';
import { MobiscrollDatepicker } from '../Datepicker/MobiscrollDatepicker';
import InfoCircleIcon from '../../assets/info-circle.png';
import { API } from '../../config';
import axios from 'axios';

const noValidateIcon = 'none';
const validateTickIcon = `url(${TickIcon})`;
const validateErrorIcon = `url(${ErrorIcon})`;
const arrowIcon = `url(${Arrow})`;

const VALIDATION_URL = `${API}/validate/`;

interface ValidationResponse {
  zipcode: {
    isValid: boolean;
  };
}


export const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'AA', label: 'Armed Forces America' },
  { value: 'AE', label: 'Armed Forces Europe/CANADA/Middle East/Africa' },
  { value: 'AP', label: 'Armed Forces Pacific' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'GU', label: 'Guam' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MP', label: 'Mariana Islands, Northern' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const ContextualForm = (props: BaseComponentProps): JSX.Element => {
  const { inputs, onSubmit, isMobile, isSubmitted,selectedHealthInsuranceType, handleTermsCheckbox , isAcceptedTerms } = props;
  const [inputValues, setInputValues] = useState<Array<Input>>(inputs);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [selectedStateValue, setSelectedStateValue] = useState('');

  const postalCodeCheck = async (value: string) => {
    const response = await axios.get<ValidationResponse>(`${VALIDATION_URL}?zipcode=${value}`);
    return response.data.zipcode.isValid;
  };

  const [validationIcons, setValidationIcons] = useState<Record<string, string>>({
    FIRST_NAME: noValidateIcon,
    LAST_NAME: noValidateIcon,
    PHONE_NUMBER: noValidateIcon,
    GENDER: arrowIcon,
    EMAIL: noValidateIcon,
    ZIPCODE: noValidateIcon,
    DOB: noValidateIcon,
    PHARMACY_NAME: arrowIcon,
    ADDRESS: noValidateIcon,
    ADDRESS_1: noValidateIcon,
    ADDRESS_2: noValidateIcon,
    CITY: noValidateIcon,
    STATE: arrowIcon,

  });

  const optionalFields = ['ADDRESS_2'];


  const theme = useTheme();
  const styles = contextualFormStyles(theme);

  useEffect(() => {
    console.log('isAcceptedTerms', isAcceptedTerms);
  }, [isAcceptedTerms] );

  useEffect(() => { 
    console.log('inputs', inputs);
  }, [inputs]);

  useEffect(() => {
    const isFormValid = inputValues.every(input => {
      const isOptionalField = optionalFields.includes(input.key);
      return isOptionalField || (input.value && input.value.trim() !== '' && validationIcons[input.key] === validateTickIcon);
    });

    const isTermsAccepted = selectedHealthInsuranceType !== 'None/Other' || isAcceptedTerms;
    setSubmitDisabled(!isFormValid || !isTermsAccepted);

  }, [inputValues, validationIcons, isAcceptedTerms]);


  const defaultOnChange = async (key: string, value: string) => {
    const updatedInputValues = inputValues.map((input) => (input.key === key ? { ...input, value } : input));
    setInputValues(updatedInputValues);

    // Validate input values
    const updatedValidationIcons = { ...validationIcons };
    switch (key) {
    case 'FIRST_NAME':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon : Validator.isAlpha(value, 'en-US', { ignore: ' .' }) ? validateTickIcon : validateErrorIcon;
      break;
    case 'LAST_NAME':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon : Validator.isAlpha(value, 'en-US', { ignore: ' .' }) ? validateTickIcon : validateErrorIcon;
      break;
    case 'ADDRESS_1':
    case 'ADDRESS_2':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon : validateTickIcon;
      break;
    case 'CITY':
    case 'STATE': 
      updatedValidationIcons[key] = !value || value === '' ? validateErrorIcon :  validateTickIcon;
      break;
    case 'PHONE_NUMBER':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon :
        (
          Validator.isMobilePhone(value, 'en-US') && 
          (
            /^(?:\+1[0-9]{10}|[0-9]{10})$/.test(value) || 
            /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
          )
        ) ? validateTickIcon : validateErrorIcon;  
      break;
    case 'EMAIL':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon : Validator.isEmail(value) ? validateTickIcon : validateErrorIcon;
      break;
    case 'ZIPCODE':
      if (!value || value === '') {
        updatedValidationIcons[key] = noValidateIcon;
      } else if (Validator.isPostalCode(value, 'US')) {
        let check = false;
        if (value.length === 5) {
          try {
            setSubmitDisabled(true);
            check = await postalCodeCheck(value);
          } catch (error) {
            console.error('Error checking postal code:', error);
          } finally {
            setSubmitDisabled(false);
          }
        }
        console.log('check ZIPCODE', check);  
        updatedValidationIcons[key] = check ? validateTickIcon : validateErrorIcon;
      } else {
        updatedValidationIcons[key] = validateErrorIcon;
      }
      break;
    case 'DOB':
      updatedValidationIcons[key] = !value || value === '' ? noValidateIcon : isDateValid(new Date(value)) ? validateTickIcon : validateErrorIcon;
      break;
    case 'GENDER':
      updatedValidationIcons[key] = value ? validateTickIcon : arrowIcon;
      break;
    default:
      break;
    }
    setValidationIcons(updatedValidationIcons);

  };

  const isDateValid = (date: Date): boolean => {
    const today = new Date();
    const eighteenYearsAgo = new Date(today.getFullYear() - 4, today.getMonth(), today.getDate());
    return date <= eighteenYearsAgo;
  };

  const handleSubmit = () => {
    const formattedInputValues = inputValues.map(input => {
      if (input.key === 'PHONE_NUMBER') {
        // Check if the phone number is in the XXX-XXX-XXXX format
        if (/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(input.value)) {
          // Convert to XXXXXXX format without dashes
          input.value = input.value.replace(/-/g, '');
        }
      }
      return input;
    });
  
    console.log(formattedInputValues);
    onSubmit && onSubmit(inputValues);
  };
  const renderInput = (input: Input) => {
    if (input.key === 'GENDER') {
      return (
        <Stack horizontal horizontalAlign='space-between' style={{ position: 'relative', minWidth: '100%' }}>
          <select
            key={input.id}
            className={mergeStyles(styles.selectField)}
            name={input.name}
            defaultValue={input.value}
            onChange={(e) => {
              defaultOnChange(input.key, e.target.value);
              setSelectedValue(e.target.value);
            }}
            style={{
              position: 'relative',
              width: '100%',
              backgroundImage: arrowIcon,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right top',
              paddingLeft: '0.2rem',
              color: selectedValue ? '#545C64' : '#545C6480',
            }}
          >
            <option hidden disabled value="">
              Gender*
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="prefernottoanswer">Other</option>
          </select>
          {selectedValue && (<div
            style={{
              position: 'absolute',
              top: '60%',
              right: '1rem',
              transform: 'translateY(-50%)',
            }}
          ><img src={TickIcon} style={{ width: '24px', height: '24px' }} />
          </div>)}
        </Stack>
      );
    } else if (input.key === 'DOB') {
      return (
        <MobiscrollDatepicker
          defaultValue={input.value ? new Date(input.value) : undefined}
          placeholder={input.name}
          onChange={(value) => {
            defaultOnChange(input.key, value.toLocaleDateString('en-US'));
          }}
          validationLogic={isDateValid}
        />
      );
    } else if (input.key === 'PHARMACY_NAME') {
      return (
        <select
          key={input.id}
          className={mergeStyles(styles.selectField)}
          name={input.name}
          defaultValue={input.value}
          onChange={(e) => {
            console.log('PHARMACY_NAME', e.target.value);
            defaultOnChange(input.key, e.target.value);
          }}
          style={{
            backgroundImage: `${validationIcons[input.key]}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top',
            paddingLeft: '0.2rem',
          }}
        >
          <option hidden disabled value="">
            Pharmacy Name
          </option>
          <option value="1">Walmart</option>
        </select>
      );
    } else if (input.key === 'STATE') {
      return (
        <Stack horizontal horizontalAlign='space-between' style={{ position: 'relative', minWidth: '100%' }}>
          <select
            key={input.id}
            className={mergeStyles(styles.selectField)}
            name={input.name}
            defaultValue={input.value}
            onChange={(e) => {
              defaultOnChange(input.key, e.target.value);
              setSelectedStateValue(e.target.value);
            }}
            style={{
              position: 'relative',
              width: '100%',
              backgroundImage: arrowIcon,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right top',
              paddingLeft: '0.2rem',
              color: selectedStateValue ? '#545C64' : '#545C6480',
            }}
          >
            <option value="" hidden disabled>
              State*
            </option>
            
            {states.map((state) => (
              <option key={state.value} value={state.value}>
                {state.label}
              </option>
            ))}
          </select>
          {selectedStateValue && (<div
            style={{
              position: 'absolute',
              top: '60%',
              right: '1rem',
              transform: 'translateY(-50%)',
            }}
          ><img src={TickIcon} style={{ width: '24px', height: '24px' }} />
          </div>)}
        </Stack>
      );
    } else {
      let maxLength;
      if (input.key === 'PHONE_NUMBER') {
        maxLength = 12;
      } else if (input.key === 'ZIPCODE') {
        maxLength = 5;
      }
      return (
        <input
          key={input.id}
          className={mergeStyles(styles.inputField)}
          type={input.type}
          name={input.name}
          defaultValue={input.value}
          placeholder={input.name}
          onChange={(e) => defaultOnChange(input.key, e.target.value)}
          style={{
            backgroundImage: `${validationIcons[input.key as keyof typeof validationIcons]}`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right top',
            padding: isMobile ? '0': '0 0 0 0.2rem',
          }}
          maxLength={maxLength}
        />
      );
    }
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const tooltipElement = document.getElementById('dob-info-tooltip');
      if (tooltipElement && !tooltipElement.contains(event.target as Node)) {
        setIsTooltipOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleTooltip = () => {
    console.log('toggleTooltip');
    setIsTooltipOpen(!isTooltipOpen);
  };

  const renderTooltipContent = () => {
    return (
      <div id='dob-info-tooltip' style={{ 
        position: 'absolute', 
        top: '100%', 
        left: '0', 
        transform: 'translateX(-90%)', 
        background: '#54555A', 
        padding: '10px', 
        borderRadius: '4px', 
        zIndex: 999, 
        minWidth: isMobile ? '40vh' : '25vw', 
        marginTop: '0.6rem', 
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', 
        width: '350px !important'
      }}>
        <div style={{ 
          position: 'absolute', 
          top: '-8px', 
          right: 'calc(5%)', 
          width: '0', 
          height: '0', 
          borderBottom: '15px solid #54555A', 
          borderLeft: '15px solid transparent', 
          borderRight: '15px solid transparent' 
        }}></div>
        <p style={{ textAlign: 'center', fontSize: '16px' }}>
          Make sure the patient is 18 years of age or older OR between the ages of 4-17, and accompanied by parent or guardian
        </p>
      </div>
    );
  };

  if(selectedHealthInsuranceType == null || selectedHealthInsuranceType === 'Government Sponsored' || selectedHealthInsuranceType === 'Kaiser Permanente'){
    return (
      <>
        <PrimaryButton
          disabled={selectedHealthInsuranceType == null}
          className={mergeStyles(styles.button)}
          styles={{
            rootDisabled: {
              backgroundColor: '#fff',
              color: '#999',
            },
          }}
          onClick={handleSubmit}
        >
          {/* <span style={{ marginTop: '4px' }}></span> */}
          SUBMIT
        </PrimaryButton>
      </>
    );
  }

  return (
    <Stack horizontalAlign="center" style={{ width:`${isMobile ? '100%' : '80%'}`, display: 'flex', pointerEvents: isSubmitted ? 'none' : 'auto' }}>
      <Stack horizontalAlign="center" style={{ width: `${isMobile ? '100%' : '100%'}` }}>
        <ul className={mergeStyles(styles.ul)} style={{ gap: '1rem' }}>
          <Stack horizontal wrap styles={{ root: { gap: '2rem', flexWrap: 'wrap', justifyContent: 'space-between' } }}>
            {inputs.map((input, index) => (
              <li key={input.id} style={{ 
                width: !isMobile ? 'calc(50% - 10rem)' : '100%' , 
                margin: isMobile ? '1rem' : '0 1rem 2rem 1rem', 
                display: 'block', 
                marginLeft: !isMobile ? (index % 2 === 0 && index !== inputs.length - 1) ? 'auto' : '4.1rem' : 'auto', 
                marginRight:  'auto',
              }}>
                <Stack horizontal>
                  <Stack style={{ width: '100%' }}>{renderInput(input)}</Stack>
                  {input.key === 'DOB' && (
                    <Stack style={{ background : '#FFFBFB', border: 'none', marginLeft: '2.5rem', position: 'relative', marginRight: isMobile ? '-3rem' : '-6rem'}}>
                      <img src={InfoCircleIcon} style={{ width: '24px', height: '24px' }} onClick={toggleTooltip} onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}/>
                      {isTooltipOpen && renderTooltipContent()}
                    </Stack>
                  )}
                </Stack>
              </li>
            ))}
          </Stack>
        </ul>
      </Stack>
      <Stack horizontal horizontalAlign='center'>
        <Text
          style={{color: '#787878', padding: '0.5rem', fontSize: '14px', width: '80%', textAlign: 'left', pointerEvents: 'auto'}}
        >
          Privacy Notice: For information on how we collect and process your personal data, 
          including the categories we collect, purposes for their collection, 
          and disclosures to third parties, visit {' '}
          <Link
            href='https://www.freestyle.abbott/in-en/privacy-policy.html' 
            target='_blank'
            style={{ color: '#0875DE' }}
          > 
            <u>Privacy Policy</u>
          </Link>. 
        </Text>
      </Stack>
      {selectedHealthInsuranceType == 'None/Other' && (      
        <Stack horizontal verticalAlign="start" style={{ margin: isMobile? '0.7rem' : '1rem', alignItems: 'center', maxWidth: '80%' }}>
          <Checkbox
            className="cardcheckbox"
            styles={{
              checkbox: {
                backgroundColor: '#FFFFFF',
                borderWidth: '1.2px',
                border: '1px solid #A9AEB2 !important',
                // borderRadius: '50%',
                height: '16px',
                width: '16px',
                
              },
              checkmark: {
                fontSize: '0px',
                width: '10px',
                height: '10px',
                textAlign: 'center',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                verticalAlign: 'middle',
                color: '#F8D347 !important',
                backgroundColor: '#F8D347 !important',
                zIndex: 1,
                // borderRadius: '50%',
                position: 'relative',
              },
            }}
            onChange={() => handleTermsCheckbox(!isAcceptedTerms)}
          />
          
          <label
            style={{
              fontSize: isMobile ? '15px' : '18px',
              color: theme.palette.black,
              paddingLeft: '1rem',
              whiteSpace: 'pre-line',
              textAlign: 'left'
            }}
          >
            <Text
              style={{
                color: theme.palette.black,
                textAlign: 'left'
              }}
            >
              I understand and agree to the {' '}
              <Link style={{ color: theme.palette.black }} href="https://portal.trialcard.com/abbott/terms-and-conditions" target="_blank">
                <u>FreeStyle Libre Savings Program Terms & Conditions</u>
              </Link>
              {' '}and that I have read and understand the {' '}
              <Link style={{ color: theme.palette.black, fontFamily: 'Open Sans' }} href="https://www.abbott.com/privacy-policy.html" target="_blank">
                <u>Abbott Privacy Policy</u>.
              </Link>
              
            </Text>
          </label>
        </Stack>
      )}
      <Stack>
        {!isSubmitted && (
          <>
            <PrimaryButton
              disabled={submitDisabled}
              className={mergeStyles(styles.button)}
              styles={{
                rootDisabled: {
                  backgroundColor: '#fff',
                  color: '#999',
                },
              }}
              onClick={handleSubmit}
            >
              {/* <span style={{ marginTop: '4px' }}></span> */}
              SUBMIT
            </PrimaryButton>
          </>
        )}
          
        {inputs.some((input) => input.key === 'PHARMACY_NAME') && (
          <PrimaryButton
            className={mergeStyles(styles.button)}
            style={{
              marginTop: '1rem',
              color: '#242482',
              border: '1px solid #242482',
              backgroundColor: '#fff',
            }}
            onClick={handleSubmit}
          >
            <span style={{ marginTop: '4px' }}>I DO NOT KNOW YET</span>
          </PrimaryButton>
        )}
      </Stack>
    </Stack>
  );
};


