import { Stack } from '@fluentui/react';
import React from 'react';
import FreestyleHeadingLogo from '../assets/freestyle_logo.png';
import AbbottLogo from '../assets/abbott-logo.png';
// import SyringeLogo from '../assets/syringe-icon-25px.png';
// import PillLogo from '../assets/pill-icon-25px.png';

// eslint-disable-next-line
export const Header = (props: { isMobile: boolean }) => {
  const { isMobile } = props;

  return (
    <Stack
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      {/* Example "Top Stack" (optional) */}
      {/* 
        <Stack
          style={{
            backgroundColor: '#f8d347',
            height: isMobile ? '40px' : '50px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: isMobile ? '0.25rem' : '0.5rem',
            flex: 1,
          }}
        >
          <Icon
            style={{
              color: 'black',
              fontSize: isMobile ? '1rem' : '1.5rem',
              fontWeight: 'bold',
              marginRight: isMobile ? '10%' : '20%',
            }}
            iconName="Zoom"
          />
        </Stack>
      */}

      {/* Middle Stack (Main Header with Logos) */}
      <Stack
        // Switch to column on mobile to stack logos if desired
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',

          // Adjust height and padding for mobile vs. desktop
          height: isMobile ? 'auto' : '50px',
          padding: isMobile ? '1rem' : '2.5rem',
          flex: 1,
        }}
      >
        <img
          style={{
            // Smaller on mobile
            height: isMobile ? '4rem' : '5.5rem',
            // Tweak margins for mobile layout
            marginLeft: isMobile ? '0' : '10%',
            marginBottom: isMobile ? '0.5rem' : '0',
          }}
          src={FreestyleHeadingLogo}
          alt="FreeStyle Libre"
        />
        <img
          style={{
            height: isMobile ? '4rem' : '5.5rem',
            marginRight: isMobile ? '0' : '10%',
          }}
          src={AbbottLogo}
          alt="Abbott"
        />
      </Stack>

      {/* Bottom Stack (Optional Navigation / Icons) */}
      {/* 
        <Stack
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            justifyContent: isMobile ? 'flex-start' : 'space-around',
            padding: isMobile ? '0.5rem 1rem' : '0.5rem 10%',
            gap: isMobile ? '0.5rem' : '5%',
            fontWeight: 'bold',
          }}
        >
          <a
            href="#"
            style={{ textDecoration: 'none', color: '#001489' }}
            target="_blank"
            rel="noreferrer"
          >
            ABOUT FREESTYLE LIBRE
          </a>
          <a
            href="#"
            style={{ textDecoration: 'none', color: '#001489' }}
            target="_blank"
            rel="noreferrer"
          >
            PROVEN RESULTS
          </a>
          <a
            href="#"
            style={{ textDecoration: 'none', color: '#001489' }}
            target="_blank"
            rel="noreferrer"
          >
            GLUCOSE MONITORING
          </a>
          <a
            href="#"
            style={{ textDecoration: 'none', color: '#001489' }}
            target="_blank"
            rel="noreferrer"
          >
            CONNECT WITH US
          </a>

          <Stack style={{ flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
            <img src={SyringeLogo} alt="FreeStyle Libre" />
            <hr
              style={{
                height: '75%',
                width: '1px',
                margin: '0.25rem',
                backgroundColor: 'black',
              }}
            />
            <img src={PillLogo} alt="FreeStyle Libre" />
          </Stack>
        </Stack>
      */}
    </Stack>
  );
};
