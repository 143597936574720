/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon, mergeStyles, PrimaryButton, Stack, Text, useTheme } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import { EnrollmentResponse } from './EnrollmentForm';

// async function mergeImages(image1Url: string, image2Url: string) {
//   const image1: any = await loadImage(image1Url);
//   const image2: any = await loadImage(image2Url);

//   const canvas = document.createElement('canvas');
//   canvas.width = image1.width;
//   canvas.height = image1.height;
//   const ctx: any = canvas.getContext('2d');

//   ctx.drawImage(image1, 0, 0);
//   ctx.drawImage(image2, 0, 0);

//   const mergedImageUrl = canvas.toDataURL('image/png');
//   return mergedImageUrl;
// }

// function loadImage(url: string) {
//   return new Promise((resolve, reject) => {
//     const img = new Image();
//     img.crossOrigin = 'anonymous';
//     img.onload = () => resolve(img);
//     img.onerror = reject;
//     img.src = url;
//   });
// }


export const SavingsCard = (props: {
  isMobile: boolean;
  data?: EnrollmentResponse;
  healthInsuranceType?: any;
}) => {
  const {  isMobile,data, healthInsuranceType } = props;
  const theme = useTheme();
  const [ios, setIOS] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    const parser = new UAParser(userAgent);
    const os = parser.getOS().name;

    setIOS(os === 'iOS');
  }, [ios]);

  // useEffect(() => {
  //   if (data && data.enrollment && data.enrollment.ezWalletResponse) {
  //     const baseImagePath = data.enrollment.ezWalletResponse.copayCardImageUrl;
  //     const overlayImagePath = OverlayImage;
  //     mergeImages(baseImagePath, overlayImagePath)
  //       .then(mergedImageUrl => {
  //         setSavingsCardImage(mergedImageUrl);
  //       })
  //       .catch(error => {
  //         console.error('Error merging images:', error);
  //       });
  //   }
  // }, [data]);
  

  return (
    <>
      <Stack  verticalAlign="center" style={{paddingBottom: '0rem'}}>
        <Stack
          horizontalAlign="center"
          style={{
            width: isMobile ? '90%' : '100%',
            height: 'fit-content',
            background: '#ffffff',
            color: '#54555A',
            marginBottom: '0.5rem',
            fontSize: '16px',
            fontWeight: isMobile ? '400' : '900',
            paddingBottom: '1rem',
            borderBottom: '10px solid #F8D347',
          }}
        >
          <Stack horizontalAlign="center" style={{marginTop: '1rem'}} >
            {healthInsuranceType === 'None/Other' ? (
              <>
                <Text
                  style={
                    {
                      color: '#54555A',
                      fontSize: '18px',
                      lineHeight: '25.6px',
                      margin: '2rem',
                      backgroundColor: '#ECEEEE',
                      padding: '1rem',
                      borderRadius: '11px',
                    }
                  
                  }
                >
                  Abbott is committed to access and affordability. We are pleased to offer a Savings Card to help you pay no more than $74.97 for 2 FreeStyle Libre CGM sensors.
                </Text>
              </>
            ) : (
              <>
                <Text style={{fontSize: isMobile ? '24px' : '48px', fontWeight: '700', color: '#54555A', fontFamily: 'Helvetica'}}>
                  YOU ARE ENROLLED!
                </Text>
              </>
            )}
            <Text style={{fontSize: '18px', fontWeight: '400', color: '#54555A',fontFamily: 'Helvetica'}}>Here&apos;s your Savings Card.</Text>
          </Stack>

          <Stack
            style={{
              height: '50px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0.5rem',
              flex: '1',
              flexDirection: isMobile ? 'column' : 'row',
              gap: '2rem',
              marginTop: isMobile ? '1rem' : '3rem',
            }}
          >
            <Stack>
              <img src={data?.enrollment?.ezWalletResponse?.copayCardImageUrl} style={{height: '200px'}} alt="Savings Card" />

            </Stack>
            <span style={{ padding: isMobile ? '0' :'2rem', color: '#C4C4C4' }}></span>
            <Stack>
              <img style={{height: '104px', width: '104px'}} src={data?.enrollment.ezWalletResponse.qrImageUrl}  alt="Text Save Bubble" />
              <p
                style={{
                  color: '#242482',
                  fontSize: '16px',
                  flexDirection: 'column',
                  fontWeight: '400',
                  lineHeight: '18.4px',
                  fontFamily: 'Helvetica'
                }}
              >
                Scan and <br /><b>Add to Wallet</b>
              </p>
            </Stack>
            <span style={{ padding: isMobile ?'0' : '2rem', color: '#C4C4C4', fontFamily: 'Helvetica', }}>OR</span>
            <Stack>
              <PrimaryButton
                href={data?.enrollment.ezWalletResponse.qrPdfUrl}
                download="savings-card.pdf"
                target='_blank'
                className={mergeStyles({
                  height: '3rem',
                  paddingLeft: '1.5rem',
                  paddingRight: '1.5rem',
                  minWidth: '40%',
                  borderRadius: theme.effects.roundedCorner4,
                  boxShadow: theme.effects.elevation8,
                  color: '#242482',
                  background: 'white',
                  border: '1px solid #242482',
                  ':hover': {
                    background: '#242482',
                    border: '1px solid #242482',
                    color: '#FFFFFF',
                    'i': { // Targeting the icon within the button
                      color: '#FFFFFF !important', // Icon color on hover
                    },
                  },
                  ':active': {
                    background: '#242482',
                    border: '1px solid #242482',
                    color: '#FFFFFF',
                  },
                  fontFamily: 'Centra No2',
                  fontWeight: '400'
                })}
              >
                <Icon iconName="Download"
                  className={mergeStyles({
                    fontSize: '20px',
                    marginRight: '0.5rem', 
                    color: '#242482', 
                  })}
                />
                Download
              </PrimaryButton>
            
            </Stack>
          </Stack>
          <Stack horizontalAlign='start' style={{width: '90%', marginTop: '1rem', textAlign:'start'}}>
            <Text style={{color: '#54555A', fontSize: '16px', lineHeight: '18.4px',fontFamily: 'Helvetica'}}>
              NOTE: You will also be sent your card information in sms. 
              <br />

              {/* {healthInsuranceType !== 'None/Other' && healthInsuranceType !== 'IDontKnow' && (
                <>
                  There is a Prior Authorization required. Typically, approval takes 5-7 days.We will update you during the process. 
                  <br />
                  Your Digital Savings Card will still work. Your out of pocket cost should not exceed $74.99.
                </>
              )} */}

              {/* {healthInsuranceType == 'IDontKnow' && healthInsuranceType !== 'None/Other' && (
                <>
                  Please go to the pharmacy and show your pharmicist your Digital Savings Card.
                </>
              )} */}
                          
            </Text>
          </Stack>
        </Stack>
       
      </Stack>
    </>
  );
};
