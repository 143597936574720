/* eslint-disable react/no-unescaped-entities */
import { initializeIcons, Stack, ThemeProvider } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import UAParser from 'ua-parser-js';
import { EnrollmentForm } from '../components/EnrollmentForm';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { FreestyleTheme } from './Freestyle.theme';
import FreestyleHeadingLogo from '../assets/freestyle_header.png';
import FreestyleHeadingMobileLogo from '../assets/freestyle_logo_mobile.png';

initializeIcons();

export const FreestyleWeb = () => {
  const [isMobile, setIsMobile] = useState(false);

  const freestyleTheme = {
    ...FreestyleTheme,
    fonts: {
      ...FreestyleTheme.fonts,
    },
  };

  useEffect(() => {
    const parser = new UAParser(navigator.userAgent);
    const deviceType = parser.getDevice().type;
    console.log('deviceType', deviceType);

    const handleResize = () => {
      const isNarrowScreen = window.innerWidth <= 768;
      setIsMobile(deviceType === 'mobile' || isNarrowScreen);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  return (
    <ThemeProvider
      theme={freestyleTheme}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        background: '#EBEBEB',
      }}
    >
      {/* {!isMobile && (<Header isMobile={isMobile} />)} */}
      <Header isMobile={isMobile} />
      <Stack
        verticalAlign="center"
        style={{
          maxWidth: '100%',
          color: '#FFFFFF',
          height: 'fit-content',
          background: '#EBEBEB',
          marginTop:  `${isMobile ? '0' : '2rem'}`,
          paddingTop: `${isMobile ? '0' : '5rem'}`,
          textAlign: `${isMobile ? 'left' : 'center'}`,
          
        }}
      >
        <Stack style={{ width: `${isMobile ? '100%' : '1000px'}`, margin: 'auto' }}>
          <Stack horizontalAlign="center">
            <img src={isMobile  ?  FreestyleHeadingMobileLogo : FreestyleHeadingLogo} style={{ width: '100%' , marginTop: `${isMobile ? '-3px' : '0'}` }} />
          </Stack>
          <EnrollmentForm isMobile={isMobile} />
        </Stack>
      </Stack>

      <Stack
        horizontalAlign="center"
        style={{
          width: '100%',
          fontSize: '12px',
          lineHeight: '15px',
          fontWeight: '400',
          color: '#54555A',
          fontFamily: 'Helvetica'
        }}
      >
        <Stack
          horizontalAlign="start"
          style={{ width: `${isMobile ? '90%' : '1000px'}`, marginBottom: '1rem'}}
        >
          <div>
            <p>Abbott provides this information as a courtesy and does not guarantee payment or coverage.</p>
            <p>
              Commercially insured and cash paying patients are eligible for the FreeStyle Libre Savings program (“Program”). 
              This Program is available for patients with Type 1, Type 2, or gestational diabetes. Patients ages 18 and older 
              are eligible to sign up for the Program. Patients ages 4-17 are eligible for the Program through their parent or guardian. 
              This Program is void where prohibited by law. Abbott may modify or rescind this Program at any time without notice. 
              This Program is not available to beneficiaries of Kaiser Permanente, Medicare, Medicaid or other federal or state 
              healthcare programs, residents of Massachusetts, or US territories (other than Puerto Rico). 
              The Program is not provided as any inducement for future purchases. This Program is only available at participating 
              pharmacies, which are subject to change without notice. The actual amount a patient pays may vary.
            </p>
            <p>The product images are for illustrative purposes only.</p>
            <p>
              This website and the information contained herein is intended for use by residents of
              the United States.
            </p>
            <p style={{ textTransform: 'uppercase' }}>
              <strong>Important Safety Information</strong>
            </p>
            <p>
              <strong>FreeStyle Libre 2 and FreeStyle Libre 3 systems:</strong> Failure to use
              FreeStyle Libre 2 or FreeStyle Libre 3 systems as instructed in labeling may result in
              missing a severe low or high glucose event and/or making a treatment decision,
              resulting in injury. If glucose alarms and readings do not match symptoms or
              expectations, use a fingerstick value from a blood glucose meter for treatment
              decisions. Seek medical attention when appropriate or contact Abbott at{' '}
              <a style={{ color: '#54555A' }} href="tel:855-632-8658">
                855-632-8658
              </a>{' '}
              or{' '}
              <a style={{ color: '#54555A' }} href="https://www.freestyle.abbott/us-en/safety-information.html#FreeStyleLibre2ImportantSafetyInformation">
                FreeStyleLibre.us
              </a>{' '}
              for safety info.
            </p>
            <p>
              The circular shape of the sensor housing, FreeStyle, Libre, and related brand marks
              are marks of Abbott. Other trademarks are the property of their respective owners.
            </p>
            <p>
              No use of any Abbott trademark, trade name, or trade dress in this site may be made
              without prior written authorization of Abbott Laboratories, except to identify the
              product or services of the company.
            </p>           
            <p style={{ textAlign: 'right' }}>
              <strong>ADC-106194 v1.0</strong>
            </p>
          </div>
        </Stack>
      </Stack>

      {/* {!isMobile && (<Footer isMobile={isMobile} />)} */}
      <Footer isMobile={isMobile} />
    </ThemeProvider>
  );
};
