export const FreestyleTheme = {
  palette: {
    themePrimary: '#FFFFFF',
    themeLighterAlt: '#0a0703',
    themeLighter: '#271d0b',
    themeLight: '#493615',
    themeTertiary: '#936d2b',
    themeSecondary: '#d79f3e',
    themeDarkAlt: '#f6bc59',
    themeDark: '#f7c672',
    themeDarker: '#f9d596',
    neutralLighterAlt: '#573263',
    neutralLighter: '#553162',
    neutralLight: '#522f5e',
    neutralQuaternaryAlt: '#4c2c57',
    neutralQuaternary: '#492a53',
    neutralTertiaryAlt: '#462850',
    neutralTertiary: '#DADADA',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#54555A',
    black50: '#545C6480',
    white: '#EFF6FF',
    green: '#69AF5D',
    linkText: '#7B8DE9',
  },
  defaultFontStyle: { fontFamily: 'Helvetica' },
  effects: {
    roundedCorner6: '10px',
    roundedCorner16: '28px',
    elevation16: '0px 4.07237px 4.07237px rgba(0, 0, 0, 0.25)',
  },
  fonts: {
    small: {
      fontSize: '14px',
    },
    medium: {
      fontSize: '16px',
    },
    large: {
      fontSize: '24px',
    },
    xLarge: {
      fontSize: '56px',
    },
  },
};
