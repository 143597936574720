import { Theme } from '@fluentui/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const searchFormStyles = (theme: Theme) => ({
  containerRoot: {
    // borderRadius: theme.effects.roundedCorner6,
    // backgroundColor: theme.palette.neutralPrimary,
    // boxShadow: theme.effects.elevation8,
    // border: `1px solid ${theme.palette.neutralTertiary}`,
  },
  headingContainerRoot: {
    borderBottom: `1px solid ${theme.palette.themeLight}`,
  },
  heading: {
    margin: '10px',
    color: theme.palette.white,
    textAlign: 'center',
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    cursor: 'pointer',
    li: {
      padding: '8px 12px',
      border: `1px solid ${theme.palette.neutralTertiary}`,
      color: theme.palette.white,
      borderRadius: theme.effects.roundedCorner6,
      boxShadow: theme.effects.elevation8,
      backgroundColor: theme.palette.neutralPrimary,
      
      margin: '0 1rem 1.2rem 1rem',
    },
  },
  inputField: {
    border: 'none',
    color: theme.palette.black,
    ':focus': {
      outline: 'none',
      color: theme.palette.black,
    },
    height: '23px',
    fontSize: '18px',
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#545C6480',
    },
    padding: '0',
    paddingRight: '0.5rem !important',
  },
  selectField: {
    border: 'none',
    color: theme.palette.black,
    ':focus': {
      outline: 'none',
      color: theme.palette.black,
    },
    height: '23px',
    fontSize: '18px',
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#777',
    },
    appearance: 'none',
    dropdown: {
      color: '#777',
    },
    paddingRight: '1.2rem !important',
  },
  button: {
    marginTop: '1rem',
    border: 'none',
    borderRadius: '6px',
    height: '42px',
    fontSize: '16px',
    fontWeight: '700',
    boxShadow: theme.effects.elevation8,
    color: theme.palette.white,
    background: '#242482',
    ':disabled': {
      background: '#D1D1D1',
      color: theme.palette.white,
      pointerEvent: 'none !important',
    },
    ':active': {
      background: '#FFFFFF',
      border: '1px solid #242482',
      color: '#242482',
    },
    ':hover': {
      background: '#FFFFFF',
      border: '1px solid #242482',
      color: '#242482',
    },
    maxWidth: '200px',
  },
  submitButton: {
    marginTop: '1.5rem',
    border: 'none',
    padding: '16px 32px',
    fontSize: '20px',
    borderRadius: '6px',
    height: '58px',
    width: '141px',
    color: theme.palette.white,
    background: '#041483',
    ':disabled': {
      background: '#D1D1D1',
      color: theme.palette.white,
    },
    ':active': {
      background: '#041483',
      border: 'none',
    },
    ':hover': {
      background: '#041483',
      border: 'none',
    },
  },
  checkBoxContainer: {
    checkbox: {
      backgroundColor: '#FFFFFF', // Change the background color to #F8D347
      borderWidth: '1.2px',
      border: '1px solid #A9AEB2 !important',
      borderRadius: '50%', // Make the checkbox round
      height: '16px',
      width: '16px',
    },
    checkmark: {
      fontSize: '0px',
      width: '10px',
      height: '10px',
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      color: '#F8D347 !important',
      backgroundColor: '#F8D347 !important',
      zIndex: 1,
      borderRadius: '50%',
    },
  },
});
