/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { Stack, useTheme, Spinner, Text, Checkbox, PrimaryButton, mergeStyles, Link } from '@fluentui/react';
import { ContextualForm } from './ContextualForm';
import { Welcome } from './Welcome';
import { SearchForm } from './SearchForm';
import { SavingsCard } from './SavingsCard';
import axios from 'axios';
import { API } from '../config';

export interface EnrollmentResponse {
  enrollment: {
    bin: string;
    pcn: string;
    groupId: string;
    memberId: string;
    ezWalletResponse: {
      qrPdfUrl: string;
      qrImageUrl: string;
      qrImageBase64Url: string;
      walletPassUrl: string;
      walletPassShortUrl: string;
      copayCardImageUrl: string;
    };
  };
}

export const EnrollmentForm = (props: { isMobile: boolean }) => {
  const { isMobile } = props;
  const theme = useTheme();

  // State variables
  const [loading, setLoading] = useState(false);
  const [enrollmentStarted, setEnrollmentStarted] = useState(false);
  const [enrollmentCompleted, setEnrollmentCompleted] = useState(false);
  const [selectedHealthInsurance, setSelectedHealthInsurance] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(['', '', '']);
  const [selectedOptIns, setSelectedOptIns] = useState<any>([]);
  const [isAcceptedTerms, setIsAcceptedTerms] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState([false, false, false]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formInputs, setFormInputs] = useState({});
  const [selectedPhysician, setSelectedPhysician] = useState({});
  const [ineligible, setIneligible] = useState(false);
  const [enrollment, setEnrollment] = useState<EnrollmentResponse>();
  const [isNoneOther, setIsNoneOther] = useState(false);
  const [progressWidth, setProgressWidth] = useState('100px');
  // Checkbox options
  const checkboxOptions = [
    { value: 'Commercial or Private (ie, United Healthcare, Blue Cross Blue Shield, etc)', label: 'Commercial or Private (ie, United\nHealthcare, Blue Cross Blue Shield, etc)' },
    { value: 'Kaiser Permanente', label: 'Kaiser Permanente' },
    { value: 'None/Other', label: 'None/Other' },
    { value: 'Government Sponsored', label: 'Government Sponsored \n(ie, Medicare, Medicaid)' },
    { value: 'I don\'t know', label: 'I don\'t know' },
  ];

  const progressWidthValue = ['0px', '100px', '300px', '800px'];

  // Step questions
  const stepQuestions: any = [
    { 
      question: 'Who are you signing up for the FreeStyle Libre Savings Program?', 
      options : [
        { value: 'Myself', label: 'Myself' },
        { value: 'Someone I care for', label: 'Someone I care for' }
      ]
    },
    { 
      question: 'What monitoring tool have you been prescribed?', 
      options : [
        { value: 'FreeStyle Libre 2 system', label: 'FreeStyle Libre 2 system' },
        { value: 'FreeStyle Libre 3 system', label: 'FreeStyle Libre 3 system' },
        { value: 'FreeStyle Libre 3 Plus system', label: 'FreeStyle Libre 3 Plus system' },
        { value: 'I don\'t know', label: 'I don\'t know' },
      ]
    },
    { 
      question: 'Patient\'s Diabetes Type?', 
      options : [
        { value: 'Type 1', label: 'Type 1' },
        { value: 'Type 2', label: 'Type 2' },
        { value: 'Gestational', label: 'Gestational' },
        { value: 'I don\'t know', label: 'I don\'t know' }
      ]
    }
  ];

  // Opt-in options
  const optInOptions = [
    { value: 'Email Opt In', label: 'Email Opt In' },
    { value: 'SMS Opt In', label: 'SMS Opt In' }
  ];

  // Email Opt In By default Checked
  useEffect(() => {
    setSelectedOptIns(['Email Opt In']);
  }, []);

  // Handler for step submission
  const handleStepSubmit = (inputs: any) => {

    // Mapping input keys to desired properties
    const keyMappings: { [key: string]: string } = {
      'FIRST_NAME': 'firstName',
      'LAST_NAME': 'lastName',
      'GENDER': 'gender',
      'DOB': 'dob',
      'PHONE_NUMBER': 'phoneNumber',
      'ZIPCODE': 'zipcode',
      'EMAIL': 'email',
      'ADDRESS_1': 'address_1',
      'ADDRESS_2': 'address_2',
      'CITY': 'city',
      'STATE': 'state',
    };

    // Initialize an empty object to hold the transformed inputs
    const transformedInputs: { [key: string]: string } = {};

    // Iterate over the inputs and map them to the desired properties
    inputs.forEach((input: any) => {
      const property = keyMappings[input.key];
      if (property) {
        transformedInputs[property] = input.value;
      }
    });

    console.log('handleStepSubmit transformedInputs', transformedInputs);
    
    const newFormInputs = { ...formInputs, ...transformedInputs };    
    setFormInputs(newFormInputs);

    if(selectedHealthInsurance === 'None/Other'){
      setIsNoneOther(true);
      return;
    }

    const newStepsCompleted = [...stepsCompleted];
    newStepsCompleted[currentStep - 1] = true;
    setStepsCompleted(newStepsCompleted);
    setCurrentStep(currentStep + 1);
    
  };

  useEffect(() => {
    console.log('currentStep', currentStep);
    setProgressWidth(progressWidthValue[currentStep]);
  }, [currentStep]);

  useEffect(() => {
    if(selectedHealthInsurance === 'None/Other'){
      setLoading(true);
      handleSubmit();
    }
  }, [isNoneOther]);

  const handlePhysicianStepSubmit = (inputs: any) => {
    console.log('handlePhysicianStepSubmit', inputs[0].value);
    setSelectedPhysician(inputs[0].value);
    const newStepsCompleted = [...stepsCompleted];
    newStepsCompleted[currentStep - 1] = true;
    setStepsCompleted(newStepsCompleted);
    setCurrentStep(currentStep + 1);
  };

  // Handler for health insurance checkbox
  const handleHealthInsuranceCheckbox = (value: any) => {
    setSelectedHealthInsurance(value === selectedHealthInsurance ? null : value);
  };

  // Handler for questions checkbox
  const handleQuestionsCheckbox = (index: number, value: any) => {
    const updatedQuestions = [...selectedQuestion];
    updatedQuestions[index] = value;
    setSelectedQuestion(updatedQuestions);
  };

  // Handler for opt-in checkbox
  const handleOptInCheckbox = (value: any) => {
    // Check if the value is already selected
    const isSelected = selectedOptIns.includes(value);
  
    if (isSelected) {
      // If selected, remove it from the selected options
      const updatedOptions = selectedOptIns.filter((optIn: any) => optIn !== value);
      setSelectedOptIns(updatedOptions);
    } else {
      // If not selected, add it to the selected options
      setSelectedOptIns([...selectedOptIns, value]);
    }
  };

  // Handler for terms checkbox
  const handleTermsCheckbox = (value: any) => {
    setIsAcceptedTerms(value);
  };

  // Handler for starting enrollment
  const startEnrollment = (value: any) => {
    setEnrollmentStarted(value);
  };

  useEffect(() => {
    console.log('isLoading', loading);
  } , [loading]);

  // Handler for form submission
  const handleSubmit = async () => {
    try {
      setLoading(true);
      console.log('Final Submit');
      const physician = selectedPhysician && Object.keys(selectedPhysician).length === 0 ? '' : selectedPhysician;
      console.log('physician', physician);

      const createEnrollmentRequest = {
        details: formInputs,
        options : {
          isEmailOptIn : selectedOptIns.includes('Email Opt In'),
          isTextOptIn : selectedOptIns.includes('SMS Opt In')
        },
        survey: {
          patientSignUpFor: selectedQuestion[0],
          prescribeQuestionValue: selectedQuestion[1],
          patientDiabetesType: selectedQuestion[2],
          patientHealthInsurance: selectedHealthInsurance,
        },
        physician,
      };
      console.log('createEnrollmentRequest', createEnrollmentRequest);
      try {
        const res = await axios.post<EnrollmentResponse>(`${API}/products/abbott/freestyle-microsite/enroll`, {
          params: createEnrollmentRequest,
        },
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        });
        
        if (res.status === 201) {
          const response = res.data;
          console.log('Success', response);
          setEnrollment(response);
          setEnrollmentCompleted(true); 
        }
      } catch (err) {
        console.error(err);
      }
      // setEnrollmentComplet ed(true);
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  // Handler for basic details submission
  const handleSubmitBasicDetails = async (inputs: any) => {
    try {
      setLoading(true);
      console.log('handleSubmitBasicDetails', inputs, selectedHealthInsurance);

      if(selectedHealthInsurance === 'Government Sponsored' || selectedHealthInsurance === 'Kaiser Permanente'){
        setIneligible(true);
      }
  
      handleStepSubmit(inputs);  

    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  // Handler for physician submission
  const handleSubmitPhysician = async (inputs: any) => {
    try {
      setLoading(true);
      handlePhysicianStepSubmit(inputs);
    } catch (err) {
      console.log('Error', err);
    } finally {
      setLoading(false);
    }
  };

  if (!enrollmentStarted) {
    return <Welcome startEnrollment={startEnrollment} isMobile={isMobile} />;
  } else if (enrollmentCompleted) {
    return <SavingsCard isMobile={isMobile} data={enrollment} healthInsuranceType={selectedHealthInsurance} />;
  } else if (ineligible) {
    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <Stack
          horizontalAlign="center"
          style={{
            width: `${isMobile ? '90%' : '100%'}`,
            height: 'fit-content',
            background: '#ffffff',
            color: '#54555A',
            padding: '2rem',
            marginBottom: '0.5rem',
            fontSize: '24px',
            fontWeight: '400',
            paddingBottom: '1rem',
            paddingTop: '0rem',
            borderBottom: '10px solid #F8D347'
          }}
        >
          <Text
            style={
              {
                color: '#54555A',
                fontSize: '18px',
                lineHeight: '25.6px',
                marginTop: '2rem',
                backgroundColor: '#ECEEEE',
                padding: '1rem',
                borderRadius: '11px',
                width: '70%'
              }
            
            }
          >
            We're sorry, based on your answer you are not eligible for the FreeStyle Libre Savings Program. Please see program{' '}
            <Link
              href="https://portal.trialcard.com/abbott/terms-and-conditions"
              target="_blank"
              styles={{
                root: {
                  color: '#0875DE',
                  textDecoration: 'underline',
                },
              }}
            >
              Terms & Conditions
            </Link> for more information. 
            <br />
            <br />
            There may be other financial assistance programs available to you. 
            If you need more information or want to discuss eligibility, please contact Abbott Consumer Relations toll-free at (844) 330-5535, 
            Monday through Friday, 8AM-8PM and Saturdays 10AM-6PM Eastern Time, excluding holidays.
          </Text>
          
        </Stack>
        
      </Stack>
      
    );
  } else {
    return (
      <Stack horizontalAlign="center" verticalAlign="center">
        <Stack
          horizontalAlign="center"
          style={{
            width: `${isMobile ? '90%' : '100%'}`,
            height: 'fit-content',
            background: '#ffffff',
            color: '#54555A',
            padding: '2rem',
            fontSize: '24px',
            fontWeight: '400',
            paddingBottom: '5rem',
            paddingTop: '1rem',
          }}
        >
          <Stack horizontal horizontalAlign="center" verticalAlign="center">
            <span>
              <b>Step 1</b>
            </span>
            <span
              style={{
                color: '#DEDEDE',
                paddingLeft: '8px',
              }}
            >
              of 3
            </span>
          </Stack>
          <Text
            style={{
              color: theme.palette.black,
              fontSize: '24px',
              lineHeight: '33.6px',
              marginTop: '2rem',
            }}
          >
            What type of health insurance do you have?
          </Text>
          <Stack
            horizontal
            wrap
            style={{
              paddingTop: `${isMobile ? '3rem' : '2rem'}`,
              width: `${isMobile ? '100%' : '70%'}`,
              height: 'fit-content',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '2rem',
              pointerEvents: stepsCompleted[0] ? 'none' : 'auto',
            }}
          >
            {checkboxOptions.map((option) => (
              <Stack key={option.value} horizontal verticalAlign="start" style={{ margin: '1rem', alignItems: 'center' }}>
                {/* <input
                  className="cardcheckbox"
                  type="checkbox"
                  value={option.value}
                  checked={selectedCheckbox === option.value}
                  onChange={() => handleCheckboxChange(option.value)}
                /> */}
                <Checkbox
                  className="cardcheckbox"
                  styles={{
                    checkbox: {
                      backgroundColor: '#FFFFFF',
                      borderWidth: '1.2px',
                      border: '1px solid #A9AEB2 !important',
                      borderRadius: '50%',
                      height: '16px',
                      width: '16px',
                    },
                    checkmark: {
                      fontSize: '0px',
                      width: '10px',
                      height: '10px',
                      textAlign: 'center',
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      verticalAlign: 'middle',
                      color: '#F8D347 !important',
                      backgroundColor: '#F8D347 !important',
                      zIndex: 1,
                      borderRadius: '50%',
                      position: 'relative',
                    },
                  }}
                  checked={selectedHealthInsurance === option.value}
                  onChange={() => handleHealthInsuranceCheckbox(option.value)}
                />
                <label
                  style={{
                    fontSize: '18px',
                    color: theme.palette.black,
                    paddingLeft: '1rem',
                    whiteSpace: 'pre-line',
                    textAlign: 'left'
                  }}
                >
                  {option.label}
                </label>
              </Stack>
            ))} 
          </Stack>
          <Stack
            horizontalAlign="center"
            style={{
              paddingTop: `${isMobile ? '3rem' : '2rem'}`,
              width: 'inherit',
              height: 'fit-content',
            }}
          >
            <ContextualForm
              inputs={[
                {
                  id: 1,
                  key: 'FIRST_NAME',
                  name: 'First Name*',
                  value: '',
                },
                {
                  id: 2,
                  key: 'LAST_NAME',
                  name: 'Last Name*',
                  value: '',
                },
                
                {
                  id: 4,
                  key: 'GENDER',
                  name: 'Gender*',
                  value: '',
                },
                {
                  id: 5,
                  key: 'DOB',
                  name: 'Date of Birth*',
                  value: '',
                },
                {
                  id: 6,
                  key: 'PHONE_NUMBER',
                  name: 'Mobile Number*',
                  value: '',
                  validator: 'phone',
                },
                {
                  id: 7,
                  key: 'EMAIL',
                  name: 'Email Address*',
                  value: '',
                  validator: 'email',
                },
                {
                  id: 8,
                  key: 'ADDRESS_1',
                  name: 'Address Line 1*',
                  value: '',
                },
                {
                  id: 9,
                  key: 'ADDRESS_2',
                  name: 'Address Line 2',
                  value: '',
                },
                {
                  id: 10,
                  key: 'CITY',
                  name: 'City*',
                  value: '',
                },
                {
                  id: 11,
                  key: 'STATE',
                  name: 'State*',
                  value: '',
                },
                {
                  id: 12,
                  key: 'ZIPCODE',
                  name: 'Zip Code*',
                  value: '',
                  validator: 'zipcode',
                },
              ]}
              onSubmit={handleSubmitBasicDetails}
              isMobile={isMobile}
              isSubmitted={stepsCompleted[0]}
              selectedHealthInsuranceType={selectedHealthInsurance}
              handleTermsCheckbox={handleTermsCheckbox}
              isAcceptedTerms={isAcceptedTerms}
            ></ContextualForm>
            
          </Stack>
          
          {stepsCompleted[0] && (
            <>
              <hr style={{border: '1px solid #F8D347',width: '70%'}}/>

              <Stack horizontal horizontalAlign="center" verticalAlign="center">
                <span>
                  <b>Step 2</b>
                </span>
                <span
                  style={{
                    color: '#DEDEDE',
                    paddingLeft: '8px',
                  }}
                >
                  of 3
                </span>
              </Stack>
              
              <Stack
                horizontalAlign="center"
                style={{
                  paddingTop: `${isMobile ? '2rem' : '2rem'}`,
                  width: 'inherit',
                  height: 'fit-content',
                }}
              ></Stack>
              <SearchForm
                inputs={[
                  {
                    id: 1,
                    key: 'PHYSICIAN',
                    name: 'Physician',
                    value: '',
                  }
                ]} 
                isMobile={isMobile}
                onSubmit={handleSubmitPhysician}
                isSubmitted={stepsCompleted[1]}
              >
              </SearchForm>
            </>
          )}       

          {stepsCompleted[1] && (
            <>
              <hr style={{border: '1px solid #F8D347',width: '70%'}}/>

              <Stack horizontal horizontalAlign="center" verticalAlign="center">
                <span>
                  <b>Step 3</b>
                </span>
                <span
                  style={{
                    color: '#DEDEDE',
                    paddingLeft: '8px',
                  }}
                >
                  of 3
                </span>
              </Stack>

              {stepQuestions.map((stepQuestion: any, index: number) => (
                <>
                  <Text
                    style={{
                      color: theme.palette.black,
                      fontSize: isMobile ? '16px' :'24px',
                      lineHeight: isMobile ? '25px' :'33.6px',
                      marginTop: '2rem',
                      textAlign: isMobile ? 'center' : 'left',
                    }}
                  >
                    {stepQuestion.question}
                  </Text>
                  <Stack
                    horizontal
                    horizontalAlign='center'
                    wrap
                    style={{
                      paddingTop: '1rem',
                      // width:  `${isMobile ? '120%' : '80%'}`,
                      
                      height: 'fit-content',
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: '1.5rem',
                      pointerEvents: stepsCompleted[2] ? 'none' : 'auto',
                    }}
                  >
                    {stepQuestion.options.map((option: any) => (
                      <Stack 
                        key={option.value} 
                        verticalAlign="start" 
                        style={
                          { 
                            margin: isMobile ? '0' :'1rem', 
                            alignItems: 'center', 
                            flexDirection: isMobile ? 'column':  'row' 
                          }
                        }>
                        <Checkbox
                          className="cardcheckbox"
                          styles={{
                            checkbox: {
                              backgroundColor: '#FFFFFF',
                              borderWidth: '1.2px',
                              border: '1px solid #A9AEB2 !important',
                              borderRadius: '50%',
                              height: isMobile ? '14px' : '16px',
                              width: isMobile ? '14px' : '16px',
                            },
                            checkmark: {
                              fontSize: '0px',
                              width: isMobile ? '8px' : '10px',
                              height: isMobile ? '8px' : '10px',
                              textAlign: 'center',
                              display: 'inline-flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              verticalAlign: 'middle',
                              color: '#F8D347 !important',
                              backgroundColor: '#F8D347 !important',
                              zIndex: 1,
                              borderRadius: '50%',
                              position: 'relative',
                            },
                          }}
                          checked={selectedQuestion[index] === option.value}
                          onChange={() => handleQuestionsCheckbox(index, option.value)}
                        />
                        <label
                          style={{
                            fontSize: isMobile ? '14px': '16px',
                            color: theme.palette.black,
                            paddingLeft: isMobile ? '0.5rem':'1rem',
                            whiteSpace: 'pre-line',
                            width: isMobile ? '85%' : 'auto',
                            textAlign: isMobile ? 'center' : 'left',
                          }}
                        >
                          {option.label}
                        </label>
                      </Stack>
                    ))} 
                  </Stack>
                  
                </>
              ))}             
            </>
          )}
          {!selectedQuestion.includes('') && (
            <>
              <hr style={{border: '1px solid #F8D347',width: '70%'}}/>
              <Text
                style={{
                  color: theme.palette.black,
                  fontSize: '24px',
                  lineHeight: '33.6px',
                  marginTop: '2rem',
                  fontWeight: '700',
                  textAlign: 'center',
                }}
              >
              Congratulations, you're eligible!
              </Text>
              <Text
              
                style={{
                  color: theme.palette.black,
                  fontSize: isMobile ? '14px' :  '16px',
                  marginTop: '2rem',
                  textAlign: 'center',
                }}
              >
              Would you like to enroll for recurring automated emails and/or texts?
              </Text>
              <Stack
                horizontal
                horizontalAlign='center'
                wrap
                style={{
                  paddingTop: '1rem',
                  width: isMobile ? '100%' :'100%',
                  height: 'fit-content',
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '2rem',
                }}
              >
                {optInOptions.map((option: any) => (
                  <Stack key={option.value} horizontal verticalAlign="start" style={{ margin: isMobile? '0.2rem' : '1rem', alignItems: 'center' }}>
                    <Checkbox
                      className="cardcheckbox"
                      styles={{
                        checkbox: {
                          backgroundColor: '#F6F6F6',
                          borderWidth: '1.2px',
                          border: '1px solid #C7C7C7 !important',
                          // borderRadius: '50%',
                          height: '16px',
                          width: '16px',
                        },
                        checkmark: {
                          fontSize: '0px',
                          width: '8px',
                          height: '8px',
                          textAlign: 'center',
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          verticalAlign: 'middle',
                          color: '#F8D347 !important',
                          backgroundColor: '#F8D347 !important',
                          zIndex: 1,
                          borderRadius: '2px !important',
                          position: 'relative',
                        },
                      }}
                      checked={selectedOptIns.includes(option.value)}
                      onChange={() => handleOptInCheckbox(option.value)}
                    />
                    <label
                      style={{
                        fontSize: isMobile ? '15px' : '18px',
                        color: theme.palette.black,
                        paddingLeft: isMobile ? '0.5rem': '1rem',
                        whiteSpace: 'pre-line'
                      }}
                    >
                      {option.label}
                    </label>
                  </Stack>
                ))} 
              
                <Stack horizontal verticalAlign="start" style={{ margin: isMobile? '0.7rem' : '1rem', alignItems: 'center', textAlign: 'left' }}>
                  <Checkbox
                    className="cardcheckbox"
                    styles={{
                      checkbox: {
                        backgroundColor: '#F6F6F6',
                        borderWidth: '1.2px',
                        border: '1px solid #C7C7C7 !important',
                        // borderRadius: '50%',
                        height: '16px',
                        width: '16px',
                        
                      },
                      checkmark: {
                        fontSize: '0px',
                        width: '8px',
                        height: '8px',
                        textAlign: 'left',
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        verticalAlign: 'middle',
                        color: '#F8D347 !important',
                        backgroundColor: '#F8D347 !important',
                        zIndex: 1,
                        borderRadius: '2px !important',
                        position: 'relative',
                      },
                    }}
                    onChange={() => handleTermsCheckbox(!isAcceptedTerms)}
                  />
                 
                  <label
                    style={{
                      fontSize: isMobile ? '15px' : '18px',
                      color: theme.palette.black,
                      paddingLeft: '1rem',
                      whiteSpace: 'pre-line',
                      textAlign: 'left'
                    }}
                  >
                    <Text
                      style={{
                        color: theme.palette.black,
                        textAlign: 'left'
                      }}
                    >
                      I understand and agree to the {' '}
                      <Link style={{ color: theme.palette.black }} href="https://www.figma.com/exit?url=https%3A%2F%2Fportal.trialcard.com%2Fabbott%2Fterms-and-conditions" target="_blank">
                        <u>FreeStyle Libre Savings Program Terms & Conditions</u>
                      </Link>
                      {' '}and that I have read and understand the {' '}
                      <Link style={{ color: theme.palette.black , fontFamily: 'Open Sans'}} href="https://www.abbott.com/privacy-policy.html" target="_blank">
                        <u>Abbott Privacy Policy</u>.
                      </Link>
                      
                    </Text>
                  </label>
                </Stack>
                
              </Stack>
            </>
          )}
          {stepsCompleted[1] && (
            <>
              <PrimaryButton
                disabled={!isAcceptedTerms || loading}
                className={mergeStyles( {
                  marginTop: '1.5rem',
                  border: 'none',
                  padding: '16px 32px',
                  fontSize: '20px',
                  fontWeight: 700,
                  borderRadius: '6px',
                  height: '58px',
                  width: '141px',
                  color: theme.palette.white,
                  background: '#041483',
                  ':disabled': {
                    background: '#D1D1D1',
                    color: theme.palette.white,
                  },
                  ':active': {
                    background: '#041483',
                    border: 'none',
                  },
                  ':hover': {
                    background: '#041483',
                    border: 'none',
                  },
                })}
                styles={{
                  rootDisabled: {
                    backgroundColor: '#fff',
                    color: '#999',
                  },
                  
                }}
                onClick={handleSubmit }
              >
                SUBMIT
              </PrimaryButton>
            </>
          )}
          {loading && (
            <Stack
              horizontalAlign="center"
              verticalAlign="center"
              style={{
                height: '10vh',
                width: 'inherit',
                color: theme.palette.green,
                background: theme.palette.themePrimary,
              }}
            >
              <Spinner />
            </Stack>
          )}
        </Stack>
        <Stack
          horizontalAlign="start"
          style={{
            width: `${isMobile ? '90%' : '100%'}`,
            color: '#FFFFFF',
            backgroundColor: '#FFFFFF',
            marginBottom: '0.5rem',
          }}
        >
          <span style={{background: '#F8D347' , height: '10px' , width: progressWidth}}></span>
        </Stack>
      
      </Stack>
    );
  }
};