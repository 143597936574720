import { mergeStyles, useTheme } from '@fluentui/react';
import React from 'react';
import { Datepicker } from '../@mobiscroll/react';
import '../@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import './MobiscrollDatepicker.css';
import TickIcon from '../../assets/validate-tick.png';
import ErrorIcon from '../../assets/validate-error.png';

export interface DatepickerProps {
  onCancel?: () => void;
  onChange?: (date: Date) => void;
  onDone?: (date: Date) => void;
  defaultValue?: Date;
  placeholder?: string;
  min?: string;
  max?: string;
  validationLogic?: (date: Date) => boolean; // New prop for validation logic
}

const noValidateIcon = 'none';
const validateTickIcon = `url(${TickIcon})`;
const validateErrorIcon = `url(${ErrorIcon})`;

export const MobiscrollDatepicker = (props: DatepickerProps): JSX.Element => {
  const { onCancel, onChange, onDone, defaultValue, placeholder, validationLogic } = props;
  const [dateValue, setDateValue] = React.useState<Date>();
  const theme = useTheme();

  const getValidationIcon = (value: Date | undefined): string => {
    if (!value) {
      return noValidateIcon;
    }

    // Check if validation logic is provided and use it to determine the icon
    if (validationLogic && validationLogic(value)) {
      return validateTickIcon;
    } else {
      return validateErrorIcon;
    }
  };

  const getMaxDate = (): string => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    return `${year}-${month}-${day}`;
  };

  return (
    <div
      className={mergeStyles({
        '& .mbsc-ios.mbsc-textfield': {
          fontFamily: `${theme.fonts.medium.fontFamily}`,
          fontSize: '18px',
          color: dateValue ? `${theme.palette.black}` : '#545C64',
          backgroundImage: getValidationIcon(dateValue),
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right top',
          paddingLeft: '0.2rem',
        },
      })}
    >
      <Datepicker
        inputStyle="box"
        controls={['date']}
        dateFormat="MM-DD-YYYY"
        touchUi={true}
        theme="ios"
        themeVariant="light"
        onChange={(event) => {
          setDateValue(event.value);
          onDone && onDone(event.value);
          onChange && onChange(event.value);
        }}
        onCancel={onCancel}
        defaultSelection={defaultValue}
        placeholder={placeholder}
        max={getMaxDate()}
      />
    </div>
  );
};
