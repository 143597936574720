import { Theme } from '@fluentui/react';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const contextualFormStyles = (theme: Theme) => ({
  containerRoot: {
    borderRadius: theme.effects.roundedCorner6,
    backgroundColor: theme.palette.neutralPrimary,
    boxShadow: theme.effects.elevation8,
    border: `1px solid ${theme.palette.neutralTertiary}`,
    fontSize: '18px'
  },
  ul: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    textAlign: 'left',
    cursor: 'pointer',
    li: {
      padding: '8px 12px',
      border: `1px solid ${theme.palette.neutralTertiary}`,
      color: theme.palette.white,
      borderRadius: theme.effects.roundedCorner6,
      boxShadow: theme.effects.elevation8,
      backgroundColor: theme.palette.neutralPrimary,
      marginBottom: '1.2rem',
    },
  },
  inputField: {
    border: 'none',
    color: theme.palette.black,
    ':focus': {
      outline: 'none',
      color: theme.palette.black,
    },
    height: '23px',
    fontSize: '18px',
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#a9adb1',
    },
    paddingRight: '1.2rem !important',
  },
  button: {
    marginTop: '1rem',
    border: 'none',
    padding: '16px 32px',
    fontSize: '20px',
    borderRadius: '6px',
    height: '58px',
    color: theme.palette.white,
    background: '#041483',
    ':disabled': {
      background: '#D1D1D1',
      color: theme.palette.white,
    },
    ':active': {
      background: '#041483',
      border: 'none',
    },
    ':hover': {
      background: '#041483',
      border: 'none',
    },
  },
  selectField: {
    border: 'none',
    color: theme.palette.black,
    ':focus': {
      outline: 'none',
      color: theme.palette.black,
    },
    height: '23px',
    fontSize: '18px',
    fontFamily: 'inherit',
    '::placeholder': {
      color: '#a9adb1',
    },
    appearance: 'none',
    dropdown: {
      color: '#777',
    },
    backgroundColor: '#fff',
    paddingRight: '2.5rem !important',
  },
  checkBoxContainer: {
    checkbox: {
      backgroundColor: '#FFFFFF', // Change the background color to #F8D347
      borderWidth: '1.2px',
      border: '1px solid #A9AEB2 !important',
      borderRadius: '50%', // Make the checkbox round
      height: '24px',
      width: '24px',
    },
    checkmark: {
      fontSize: '0px',
      width: '12px',
      height: '12px',
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      verticalAlign: 'middle',
      color: '#F8D347 !important',
      backgroundColor: '#F8D347 !important',
      zIndex: 1,
      borderRadius: '50%',
    },
  },
});
