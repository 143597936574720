/* eslint-disable react/no-unescaped-entities */
import { Stack } from '@fluentui/react';
import React from 'react';
// import ProdSaveWebQrCode from '../assets/qr_code-SAVEWEB_PROD.png';
// import UatSaveWebQrCode from '../assets/qr_code-SAVEWEB_UAT.png';
// import { ENV } from '../config';

// const saveWebQrCode = ENV === 'prod' ? ProdSaveWebQrCode : UatSaveWebQrCode;

export const Welcome = (props: {
  startEnrollment: (value: boolean) => void;
  isMobile: boolean;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { startEnrollment, isMobile } = props;
  // const theme = useTheme();
  // const qrCodeStyles = {
  //   width: '85px',
  //   height: '85px',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  // };
  return (
    <Stack horizontalAlign="center" verticalAlign="center">
      <Stack
        horizontalAlign="center"
        style={{
          width: '100%',
          height: 'fit-content',
          background: '#ffffff',
          color: '#54555A',
          marginBottom: '1rem',
          fontSize: `${isMobile ? '14px' : '24px'}`,
          fontWeight: '400',
          padding: '32px',
          textAlign: `${isMobile ? 'left' : 'center'}`,
          paddingLeft: `${isMobile ? '32px' : '10rem'}`,
          paddingRight: `${isMobile ? '32px' : '10rem'}`,
          display: 'block',
          lineHeight: '1.5',
        }}
      >
          We are no longer accepting enrollment into this program.
          If you or your patient have already enrolled previously,
          rest assured the existing copay card will continue to work until 12/31/2025.
          If patient is commercially insured or uninsured and asked to pay more than $75
          for two FreeStyle Libre 2, FreeStyle Libre 3, or FreeStyle Libre 3 Plus sensors,
          please call <a href="tel:844-330-5535" style={{ color: '#1415EF' }}>844-330-5535</a> to see if patient qualifies for co-pay assistance.

        {/* <Stack
          horizontal
          horizontalAlign="center"
          verticalAlign="center"
          style={{ paddingTop: '3rem', fontWeight: '700' , marginLeft: `${isMobile ? '1rem' : 'auto'}`, marginRight: `${isMobile ? '1rem' : 'auto'}`, textAlign: 'center' }}
        >

          <b>Choose one of the three options below to begin your brief enrollment and opt in. </b>
        </Stack>

        <Stack
          style={{
            height: '50px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0.5rem',
            flex: '1',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? '0' :'2rem',
            marginTop: '3rem',
          }}
        >
          <Stack>
            <img src={saveWebQrCode} style={qrCodeStyles} alt="QR Code" />
            <p
              style={{
                color: '#797979',
                fontSize: '16px',
                fontWeight: '700',
                flexDirection: 'column',
              }}
            >
              Scan QR Code
            </p>
          </Stack>
          <span style={{ padding:  isMobile ? '0.5rem' : '2rem', color: '#C4C4C4' }}>OR</span>
          <Stack>
            <img src={TextSaveBubble} style={qrCodeStyles} alt="Text Save Bubble" />
            <p
              style={{
                color: '#797979',
                fontSize: '16px',
                fontWeight: '700',
                flexDirection: 'column',
              }}
            >
              Text "Save" to <br />
              <span>+1-606-755-4273</span>.
            </p>
          </Stack>
          <span style={{ padding: isMobile ? '1rem' : '2rem', color: '#C4C4C4' }}>OR</span>
          <Stack>
            <PrimaryButton
              className={mergeStyles({
                height: '3rem',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                minWidth: '40%',
                borderRadius: theme.effects.roundedCorner6,
                boxShadow: theme.effects.elevation8,
                color: theme.palette.white,
                background:  '#6093CE',
                border: '1px solid #6093CE',
                ':hover': {
                  background:  '#6093CE',
                  border: '1px solid #6093CE',
                  color: theme.palette.white,
                },
                ':active': {
                  background:  '#6093CE',
                  border: '1px solid #6093CE',
                  color: theme.palette.white,
                },
              })}
              onClick={() => startEnrollment(true)}
            >
              <b>START HERE</b>
            </PrimaryButton>
            <p
              style={{
                color: '#797979',
                fontSize: '16px',
                fontWeight: '700',
                flexDirection: 'column',
              }}
            >
              Start Right Here
            </p>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
};
