import { Stack } from '@fluentui/react';
import React from 'react';
import AbboottFooterLogo from '../assets/abbott-white-logo.svg';
// import FacebookLogo from '../assets/facebook.svg';
export const Footer = (props: { isMobile: boolean }) => {

  //eslint-disable-next-line
  const { isMobile } = props;
  return (
    <>
    
      <Stack
        style={{
          maxHeight: '10%',
          background: 'black',
          marginTop: 0,
          paddingBottom: 0,
          marginBottom: 0,
        }}
      >
        <Stack
          style={{
            marginLeft: '5%',
            marginRight: '10%',
          }}
        >
          {/* <Stack
            horizontal
            verticalAlign="center"
            style={{
              padding: '1rem',
              width: 'inherit',
              margin: 0,
              color: 'yellow',
              textTransform: 'uppercase',
              gap: '2rem',
              fontWeight: 'bold',
              fontSize: '12px',
            }}
          >
            <a
              href="#"
              style={{ color: 'inherit', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Site Map
            </a>
            <a
              href="#"
              style={{ color: 'inherit', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
            <a
              href="#"
              style={{ color: 'inherit', textDecoration: 'none' }}
              target="_blank"
              rel="noreferrer"
            >
              Unsubscribe
            </a>
          </Stack>
          <hr
            style={{
              border: '1px solid #525252',
              width: 'inherit',
              padding: 0,
              margin: 0,
            }}
          ></hr> */}
          <Stack horizontal={!isMobile} horizontalAlign="start" style={{ paddingBottom: isMobile ? '1.5rem' : '5rem' }}>
            <Stack style={{ paddingTop: '2rem' }}>
              <img src={AbboottFooterLogo} style={{ height: '40px', alignSelf: 'start', paddingBottom: '1rem' }} />
              <Stack
                horizontal
                horizontalAlign="center"
                style={{
                  color: 'white',
                  fontSize: '12.25px',
                }}
              >
                <p style={{ color: 'inherit', lineHeight: '23.89px' }}>
                  © 2024 Abbott. All rights reserved. The sensor housing, FreeStyle, Libre, 
                  and related brand marks are 
                  {!isMobile && <br />}  {/* <br /> will appear only on desktop */}
                  marks of Abbott. Other trademarks are the property of their respective owners.
                </p>

              </Stack>
            </Stack>
            <Stack style={{ paddingTop: isMobile ? '1.5rem' : '4rem', marginLeft: isMobile ? '0rem' : '5rem' }}>
              {/* <Stack style={{ flexDirection: 'row', justifyContent: 'end', gap: '1rem' }}>
                <img src={FacebookLogo} style={{ height: '35px' }} />
                <img src={FacebookLogo} style={{ height: '35px' }} />
                <img src={FacebookLogo} style={{ height: '35px' }} />
                <img src={FacebookLogo} style={{ height: '35px' }} />
              </Stack> */}
              <Stack
                horizontal
                horizontalAlign="end"
                style={{
                  color: 'white',
                  fontSize: '12px',
                  gap: '1.5rem',
                  paddingTop: '1rem',
                }}
              >
                <a
                  href="https://www.abbott.com/online-terms-and-conditions.html"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of use
                </a>
                <a
                  href="https://www.abbott.com/privacy-policy.html"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                {/* <a
                  href="#"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Advertising Preferences
                </a> */}
              </Stack>
              {/* <Stack
                horizontal
                horizontalAlign="end"
                style={{
                  color: 'white',
                  fontSize: '12px',
                  gap: '1.5rem',
                  paddingTop: '1rem',
                }}
              >
                <a
                  href="#"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  HIPPA Notice of Privacy Practices
                </a>
                <a
                  href="#"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  COVID-19 Response
                </a>
                <a
                  href="#"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Cookies Preferences
                </a>
              </Stack> */}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    
    </>
  );
};
